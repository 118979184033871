/* Tab Container */
.Tabs {
    width: 100%;
}

/* Tab nav-tabigation */
ul.nav-tab {
    width: 20%;
    margin: 0 auto 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #007bff;
    border-radius: 5px;

    @media (max-width: 768px) {
        width: 90%;
    }
}

ul.nav-tab li {
    width: 50%;
    padding: 0.3rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

ul.nav-tab li:nth-child(2) {
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

ul.nav-tab li:hover {
    background: rgba(50, 224, 196, 0.15);
}

ul.nav-tab li.active-tab {
    background: #007bff;
    color: #e8f0f2;
}

.outlet {
    transition: all 0.7s;
}
