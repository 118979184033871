.thumbnail {
    border-radius: 5px;
    // max-width:300px;
    // max-height:215px;
    // width: auto;
    // height: auto;
}

.nophotos {
    height: 300px;
}

.modal {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.modalho {
    opacity: 1;
    filter: alpha(opacity=100);
}
.modalho:hover {
    opacity: 0.9;
    filter: alpha(opacity=30);
}

.traps-item {
    &:hover {
        cursor: pointer;
    }
}
