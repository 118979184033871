.trap-thumb {
    border-radius: 7px 0 0 7px !important;
    max-height: 165px;
    // object-fit: scale-down;
}

.bg-black {
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
}

.h-165 {
    height: 165px !important;
}
