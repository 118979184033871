.btn-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border-radius: 30px;
}

.soil-info {
    &:hover {
        cursor: text;
    }
    span {
        color: #949494;
        font-size: 14px;
        font-weight: 450;
    }
    p {
        margin-top: 5px;
        margin-bottom: -7px;
    }

    &_unit {
        color: black !important;
        font-size: 13px;
        // font-weight: 450;
        // margin-top: 1px;
        // margin-left: 2px;
    }
}
