.card-login {
    width: 400px;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(9px);
}

//.card-color{
    //background: rgba(255,255,255,0.6);
//}

.vh-100 {
    height: 100vh !important;
}

.login-main {
    background-image: url("./../../../assets/login_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 
