.farm-item:hover {
    cursor: pointer;
}

.farm-item {
    &:hover {
        cursor: pointer;
    }
    span {
        color: #949494;
        font-size: 14px;
        font-weight: 450;
    }
    p {
        margin-top: 5px;
        margin-bottom: -7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_unit {
        color: black !important;
        font-size: 14px;
        font-weight: 450;
    }
}

.container-fluid {
    height: 90vh;
}

a:link {
    text-decoration: none;
}

.cardcolor {
    color: black;
}

.hidden {
    display: none;
}

.cardlimit{
    overflow: hidden; // Removendo barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 1; // Quantidade de linhas
    -webkit-box-orient: vertical; 
}

.pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}