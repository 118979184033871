.farm-item:hover {
    cursor: pointer;
}

.traps-item {
    &:hover {
        cursor: pointer;
    }
    span {
        color: #949494;
        font-size: 14px;
        font-weight: 450;
    }
    p {
        margin-top: 5px;
        margin-bottom: -7px;
        max-width: 500px; // Limite maximo do texto
        white-space: nowrap; // Removendo quebra de linha
        overflow: hidden; // Removendo a barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final do texto
    }

    &_unit {
        color: black !important;
        font-size: 14px;
        font-weight: 450;
    }
}

a:link {
    text-decoration: none;
}

.cardcolor {
    color: black;
}

.hidden {
    display: none;
}

.card-description-h {
    height: 28px;
    text-overflow: ellipsis;
}

.cardheight {
    // height: 355px;
    text-overflow: ellipsis;
}

.pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}