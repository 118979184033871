.main-header {
    // height: 50px;
    color: black;
    display: flexbox;
    font-family: sans-serif;
    font-size: 17px;
}

.font-header {
    font-size: 25px;
    font-family: sans-serif;
    a {
        color: black;
        text-decoration: none;
    }
}

.aqua {
    background-color: aqua;
}

.active {
    // border-radius: 0px 0px 0px 0px !important;
    // background: #017afc !important;
    box-shadow: inset 0 -2px 0 0 #28409A; 
    // color: white !important;
}

.menu-subtopic {
    li {
        // font-size: 15px;
        display: flex;
        align-items: center;
        height: 40px;
        color: #555;
        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-left: 0px;
            text-decoration: none;
            color: #555;
        }
    }

    li:hover {
        cursor: pointer;
        // border-radius: 0px 0px 0px 0px;
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.258);
        // background: #017afc28;
    }
}

.hidden {
    display: none !important;
}

ul {
    padding-left: 0;
    list-style-type: none;
}
a {
    text-decoration: none;
    color: black;
}
