body {
    background-color: #fff !important;
    color: #333;
    overflow-x: hidden;
}

/* Default icon URLs */
.leaflet-default-icon-path {
    background-image: url(./assets/marker.png) !important;
}

.pre-loader {
    background-image: url(./assets/pre-loader.gif) !important;
    height: 90vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px ;
}

.hidden {
    display: none;
}

.dashboard-main {
    height: 70vh;
}