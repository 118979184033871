.crop-item:hover {
    cursor: pointer;
}

.crop-item {
    &:hover {
        cursor: pointer;
    }
    span {
        color: #949494;
        font-size: 14px;
        font-weight: 450;
    }
    p {
        margin-top: 25px;
        margin-bottom: -7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_unit {
        color: black !important;
        font-size: 14px;
        font-weight: 450;
    }
}

.container-fluid {
    height: 90vh;
}

a:link {
    text-decoration: none;
}

.cardcolor {
    color: black;
}

.hidden {
    display: none;
}
.cardheader {
    background-color: rgb(255, 255, 255);
    font-size: 18px;
    text-decoration: none;
}
.cardfooter {
    background-color: rgb(255, 255, 255);
}
.cardheaderbtn {
    font-size: 18px;
    color: black;
}
.cardtitle {
    font-size: 22px;
    color: black;
}
.cardbtn {
    width: 215px;
}

.pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}